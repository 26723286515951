import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { getUserPermissionsByCategory, isAdmin } from '../services/auth.service';


class Sidebar extends Component {
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: '/financials', state: 'financialsMenuOpen' },
      { path: '/visits', state: 'visitsMenuOpen' },
      { path: '/reported-cases', state: 'reportedCasesMenuOpen' },
      { path: '/admissions', state: 'admissionsMenuOpen' },
      { path: '/prescriptions', state: 'prescriptionsMenuOpen' },
      { path: '/lab', state: 'labMenuOpen' },
      { path: '/births', state: 'birthsMenuOpen' },
      { path: '/radiology', state: 'radiologyMenuOpen' },
      { path: '/mortality', state: 'mortalityMenuOpen' },
      { path: '/administration', state: 'administrationMenuOpen' },
      { path: '/tools', state: 'toolsMenuOpen' },
      { path: '/user-management', state: 'userManagementMenuOpen' },
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true })
      }
    }));

  }

  render() {
    const homeList = getUserPermissionsByCategory('Home')
    const home2List = getUserPermissionsByCategory('Home2')
    const slideshowList = getUserPermissionsByCategory('Slide Show')
    const financialsList = getUserPermissionsByCategory('Financials')
    const reportedCaseList = getUserPermissionsByCategory('Reported Cases')
    const prescribedDrugList = getUserPermissionsByCategory('Prescribed Drugs')
    const patientVisitList = getUserPermissionsByCategory('Visits')
    const labList = getUserPermissionsByCategory('Lab')
    const birthList = getUserPermissionsByCategory('Births')
    const admissionList = getUserPermissionsByCategory('Admissions')
    const radiologyList = getUserPermissionsByCategory('Radiology')
    const mortalityList = getUserPermissionsByCategory('Mortality')
    const administrationList = getUserPermissionsByCategory('Administration')
    const userIsAdmin = isAdmin()
    let toolsList = getUserPermissionsByCategory('Tools')
    toolsList = toolsList.filter(p => p.Code !== "NewsletterForm")
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <div className="text-center sidebar-brand-wrapper d-flex align-items-center">
          <a className="sidebar-brand brand-logo" href="index.html"><img src={require("../../assets/images/hnb-logo.png")} alt="logo" /></a>
          <a className="sidebar-brand brand-logo-mini pt-3" href="index.html"><img src={require("../../assets/images/hnb-logo-box.png")} alt="logo" /></a>
        </div>
        <ul className="nav">
          {homeList.length > 0 ?
            <li className={this.isPathActive('/home', true) ? 'nav-item active' : 'nav-item'} style={{ display: homeList.length > 0 ? "block" : "none" }}>
              <Link className="nav-link" to="/home">
                <i className="mdi mdi-television menu-icon"></i>
                <span className="menu-title">Home</span>
              </Link>
            </li> : null}

          {home2List.length > 0 ?
            <li className={this.isPathActive('/home2', true) ? 'nav-item active' : 'nav-item'} style={{ display: home2List.length > 0 ? "block" : "none" }}>
              <Link className="nav-link" to="/home2">
                <i className="mdi mdi-television menu-icon"></i>
                <span className="menu-title">Home2</span>
              </Link>
            </li> : null}

          {slideshowList.length > 0 ?
            <li className={this.isPathActive('/slideshow') ? 'nav-item active' : 'nav-item'}>
              <Link className="nav-link" to="/slideshow">
                <i className="mdi mdi-television-guide menu-icon"></i>
                <span className="menu-title">Slide Show</span>
              </Link>
            </li> : null}

          {financialsList.length > 0 ?
            <li className={this.isPathActive('/financials') ? 'nav-item active' : 'nav-item'}>
              <div className={this.state.financialsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('financialsMenuOpen')} data-toggle="collapse">
                <i className="mdi mdi-cash-multiple menu-icon"></i>
                <span className="menu-title">Financials</span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.financialsMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  {financialsList ? financialsList.map((object, i) =>
                    <li className="nav-item" key={i}> <Link className={this.isPathActive(object.Path, true) ? 'nav-link active' : 'nav-link'} to={object.Path}>{object.Description}</Link></li>
                  ) : null}
                </ul>
              </Collapse>
            </li> : null}

          {patientVisitList.length > 0 ?
            <li className={this.isPathActive('/visits') ? 'nav-item active' : 'nav-item'}>
              <div className={this.state.visitsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('visitsMenuOpen')} data-toggle="collapse">
                <i className="mdi mdi-hospital-building menu-icon"></i>
                <span className="menu-title">Visits</span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.visitsMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  {patientVisitList ? patientVisitList.map((object, i) =>
                    <li className="nav-item" key={i}> <Link className={this.isPathActive(object.Path, true) ? 'nav-link active' : 'nav-link'} to={object.Path}>{object.Description}</Link></li>
                  ) : null}
                </ul>
              </Collapse>
            </li> : null}

          {reportedCaseList.length > 0 ?
            <li className={this.isPathActive('/reported-cases') ? 'nav-item active' : 'nav-item'}>
              <div className={this.state.reportedCasesMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('reportedCasesMenuOpen')} data-toggle="collapse">
                <i className="mdi mdi-library-books menu-icon"></i>
                <span className="menu-title">Reported Cases</span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.reportedCasesMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  {reportedCaseList ? reportedCaseList.map((object, i) =>
                    <li className="nav-item" key={i}> <Link className={this.isPathActive(object.Path, true) ? 'nav-link active' : 'nav-link'} to={object.Path}>{object.Description}</Link></li>
                  ) : null}
                </ul>
              </Collapse>
            </li> : null}

          {admissionList.length > 0 ?
            <li className={this.isPathActive('/admissions') ? 'nav-item active' : 'nav-item'}>
              <div className={this.state.admissionsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('admissionsMenuOpen')} data-toggle="collapse">
                <i className="mdi mdi-ambulance menu-icon"></i>
                <span className="menu-title">Admissions</span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.admissionsMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  {admissionList ? admissionList.map((object, i) =>
                    <li className="nav-item" key={i}> <Link className={this.isPathActive(object.Path, true) ? 'nav-link active' : 'nav-link'} to={object.Path}>{object.Description}</Link></li>
                  ) : null}
                </ul>
              </Collapse>
            </li> : null}

          {prescribedDrugList.length > 0 ?
            <li className={this.isPathActive('/prescriptions') ? 'nav-item active' : 'nav-item'}>
              <div className={this.state.prescriptionsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('prescriptionsMenuOpen')} data-toggle="collapse">
                <i className="mdi mdi-pill menu-icon"></i>
                <span className="menu-title">Prescribed Drugs</span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.prescriptionsMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  {prescribedDrugList ? prescribedDrugList.map((object, i) =>
                    <li className="nav-item" key={i}> <Link className={this.isPathActive(object.Path, true) ? 'nav-link active' : 'nav-link'} to={object.Path}>{object.Description}</Link></li>
                  ) : null}
                </ul>
              </Collapse>
            </li> : null}

          {labList.length > 0 ?
            <li className={this.isPathActive('/lab') ? 'nav-item active' : 'nav-item'}>
              <div className={this.state.labMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('labMenuOpen')} data-toggle="collapse">
                <i className="mdi mdi-beaker-outline menu-icon"></i>
                <span className="menu-title">Laboratory</span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.labMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  {labList ? labList.map((object, i) =>
                    <li className="nav-item" key={i}> <Link className={this.isPathActive(object.Path, true) ? 'nav-link active' : 'nav-link'} to={object.Path}>{object.Description}</Link></li>
                  ) : null}
                </ul>
              </Collapse>
            </li> : null}

          {radiologyList.length > 0 ?
            <li className={this.isPathActive('/radiology') ? 'nav-item active' : 'nav-item'}>
              <div className={this.state.radiologyMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('radiologyMenuOpen')} data-toggle="collapse">
                <i className="mdi mdi-filmstrip menu-icon"></i>
                <span className="menu-title">Radiology</span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.radiologyMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  {radiologyList ? radiologyList.map((object, i) =>
                    <li className="nav-item" key={i}> <Link className={this.isPathActive(object.Path, true) ? 'nav-link active' : 'nav-link'} to={object.Path}>{object.Description}</Link></li>
                  ) : null}
                </ul>
              </Collapse>
            </li> : null}

          {birthList.length > 0 ?
            <li className={this.isPathActive('/births') ? 'nav-item active' : 'nav-item'}>
              <div className={this.state.birthsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('birthsMenuOpen')} data-toggle="collapse">
                <i className="mdi mdi-human-male-female menu-icon"></i>
                <span className="menu-title">Births</span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.birthsMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  {birthList ? birthList.map((object, i) =>
                    <li className="nav-item" key={i}> <Link className={this.isPathActive(object.Path, true) ? 'nav-link active' : 'nav-link'} to={object.Path}>{object.Description}</Link></li>
                  ) : null}
                </ul>
              </Collapse>
            </li> : null}

          {mortalityList.length > 0 ?
            <li className={this.isPathActive('/mortality') ? 'nav-item active' : 'nav-item'}>
              <div className={this.state.mortalityMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('mortalityMenuOpen')} data-toggle="collapse">
                <i className="mdi mdi-hotel menu-icon"></i>
                <span className="menu-title">Mortality</span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.mortalityMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  {mortalityList ? mortalityList.map((object, i) =>
                    <li className="nav-item" key={i}> <Link className={this.isPathActive(object.Path, true) ? 'nav-link active' : 'nav-link'} to={object.Path}>{object.Description}</Link></li>
                  ) : null}
                </ul>
              </Collapse>
            </li> : null}

          {administrationList.length > 0 ?
            <li className={this.isPathActive('/administration') ? 'nav-item active' : 'nav-item'}>
              <div className={this.state.administrationMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('administrationMenuOpen')} data-toggle="collapse">
                <i className="mdi mdi-google-circles-extended menu-icon"></i>
                <span className="menu-title">Administration</span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.administrationMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  {administrationList ? administrationList.map((object, i) =>
                    <li className="nav-item" key={i}> <Link className={this.isPathActive(object.Path, true) ? 'nav-link active' : 'nav-link'} to={object.Path}>{object.Description}</Link></li>
                  ) : null}
                </ul>
              </Collapse>
            </li> : null}

          {toolsList.length > 0 ?
            <li className={this.isPathActive('/tools') ? 'nav-item active' : 'nav-item'}>
              <div className={this.state.toolsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('toolsMenuOpen')} data-toggle="collapse">
                <i className="mdi mdi-settings menu-icon"></i>
                <span className="menu-title">Tools</span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.toolsMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  {toolsList ? toolsList.map((object, i) =>
                    <li className="nav-item" key={i}> <Link className={this.isPathActive(object.Path, true) ? 'nav-link active' : 'nav-link'} to={object.Path}>{object.Description}</Link></li>
                  ) : null}
                </ul>
              </Collapse>
            </li> : null}

          {userIsAdmin ?
            <li className={this.isPathActive('/user-management') ? 'nav-item active' : 'nav-item'}>
              <div className={this.state.userManagementMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('userManagementMenuOpen')} data-toggle="collapse">
                <i className="mdi mdi-account-key menu-icon"></i>
                <span className="menu-title">User Management</span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.userManagementMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={this.isPathActive('/user-management/role-setup', true) ? 'nav-link active' : 'nav-link'} to="/user-management/role-setup">Role Setup</Link></li>
                  <li className="nav-item"> <Link className={this.isPathActive('/user-management/role-permission-setup', true) ? 'nav-link active' : 'nav-link'} to="/user-management/role-permission-setup">Role Permissions Setup</Link></li>
                  <li className="nav-item"> <Link className={this.isPathActive('/user-management/user-setup', true) ? 'nav-link active' : 'nav-link'} to="/user-management/user-setup">User Setup</Link></li>
                  <li className="nav-item"> <Link className={this.isPathActive('/user-management/permission-setup', true) ? 'nav-link active' : 'nav-link'} to="/user-management/permission-setup">Permission Setup</Link></li>
                </ul>
              </Collapse>
            </li> : null}
        </ul>
      </nav>
    );
  }

  isPathActive(path, isPageLink = false) {
    return isPageLink ? this.props.location.pathname === path : this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {

      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });

  }

}

export default withRouter(Sidebar);