import React, { Component, Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from './auth/PrivateRoute';
import AdminRoute from './auth/AdminRoute';
import Spinner from '../app/shared/Spinner';
import { getUserRole } from './services/auth.service'

const userRole = getUserRole();

const Login = lazy(() => import('./auth/Login'));
const Error401 = lazy(() => import('./auth/Error401'));
const ReportsHome = lazy(() => import('./Home'));
const Home = lazy(() => import('./Home2'));
const HealthSlideshow = lazy(() => import('./HealthSlideshow'));
const FinancialsHome = lazy(() => import('./financials/FinancialsHome'));
const FinancialsSlideshow = lazy(() => import('./financials/FinancialsSlideshow'));
const Revenue = lazy(() => import('./financials/Revenue'));
const RevenueBreakdown = lazy(() => import('./financials/RevenueBreakdown'));
const Transaction = lazy(() => import('./financials/Transaction'));
const TransactionBreakdown = lazy(() => import('./financials/TransactionBreakdown'));
const RevenueByDepartmentReport = lazy(() => import('./financials/RevenueByDepartmentReport'));
const RevenueByPatientAndScheme = lazy(() => import('./financials/RevenueByPatientAndScheme'));
const RevenueLineItems = lazy(() => import('./financials/RevenueLineItems'));
const SettlementReport = lazy(() => import('./financials/SettlementReport'));
const NewPatients = lazy(() => import('./visits/NewPatients'));
const PatientVisits = lazy(() => import('./visits/PatientVisits'));
const ReportedCasesReport = lazy(() => import('./reported-cases/ReportedCasesReport'));
const ReportedCaseStudy = lazy(() => import('./reported-cases/ReportedCaseStudy'));
const TopReportedCases = lazy(() => import('./reported-cases/TopReportedCases'));
const Admissions = lazy(() => import('./admissions/AdmissionsReport'));
const AdmissionStudy = lazy(() => import('./admissions/AdmissionStudy'));
const PrescribedDrugsReport = lazy(() => import('./prescriptions/PrescribedDrugsReport'));
const PrescribedDrugsStudy = lazy(() => import('./prescriptions/PrescribedDrugsStudy'));
const TopPrescribedDrugs = lazy(() => import('./prescriptions/TopPrescribedDrugs'));
const LabTestsReport = lazy(() => import('./lab/LabTestsReport'));
const Births = lazy(() => import('./births/Births'));
const AverageWaitingTime = lazy(() => import('./visits/AverageWaitingTime'));
const Covid19Alert = lazy(() => import('./reported-cases/Covid19Alert'));
const Covid19Report = lazy(() => import('./reported-cases/Covid19Report'));
const OutbreakAlert = lazy(() => import('./reported-cases/LassaFeverAlert'));
const DoctorConsultationCount = lazy(() => import('./visits/DoctorConsultationCount'));
const UserSetup = lazy(() => import('./user-management/UserSetup'));
const RoleSetup = lazy(() => import('./user-management/RoleSetup'));
const RolePermissionSetup = lazy(() => import('./user-management/RolePermissionSetup'));
const PermissionSetup = lazy(() => import('./user-management/PermissionSetup'));
const RadiologyReport = lazy(() => import('./radiology/RadiologyReport'));
const DischargeReport = lazy(() => import('./admissions/DischargeReport'));
const BedsReport = lazy(() => import('./admissions/BedsReport'));
const InpatientMortalityReport = lazy(() => import('./mortality/InpatientMortalityReport'));
const LabourRoomReport = lazy(() => import('./births/LabourRoomReport'));
const AverageTreatmentCharge = lazy(() => import('./financials/AverageTreatmentCharge'));
const Departments = lazy(() => import('./administration/HospitalDepartment'));
const Newsletters = lazy(() => import('./tools/Newsletters'));
const NewsletterForm = lazy(() => import('./tools/NewsletterForm'));
const NewsletterContent = lazy(() => import('./tools/HnbNewsletter'));
const HospitalList = lazy(() => import('./administration/Hospitals'));
const RevenuePerServiceDept1 = lazy(() => import('./financials/RevenuePerServiceDept1'));
const RevenuePerServiceDept2 = lazy(() => import('./financials/RevenuePerServiceDept2'));
const DataReset = lazy(() => import('./tools/DataReset'));

class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <PrivateRoute path="/home" component={ReportsHome} />
          <PrivateRoute path="/home2" component={Home} />
          <Route path="/login" component={Login} />
          <Route path="/error-401" component={Error401} />
          <Route path="/view-newsletter/:id?" component={NewsletterContent} />
          <PrivateRoute path="/slideshow" component={HealthSlideshow} />

          <PrivateRoute path="/financials/home" component={FinancialsHome} />
          <PrivateRoute path="/financials/slideshow" component={FinancialsSlideshow} />
          <PrivateRoute path="/financials/revenue" component={Revenue} />
          <PrivateRoute path="/financials/revenue-breakdown" component={RevenueBreakdown} />
          <PrivateRoute path="/financials/transaction" component={Transaction} />
          <PrivateRoute path="/financials/transaction-breakdown" component={TransactionBreakdown} />
          <PrivateRoute path="/financials/revenue-by-department-report" component={RevenueByDepartmentReport} />
          <PrivateRoute path="/financials/revenue-by-patient-and-scheme" component={RevenueByPatientAndScheme} />
          <PrivateRoute path="/financials/revenue-line-items" component={RevenueLineItems} />
          <PrivateRoute path="/financials/settlement-report" component={SettlementReport} />
          <PrivateRoute path="/financials/average-treatment-charge" component={AverageTreatmentCharge} />
          <PrivateRoute path="/financials/revenue-per-service-department-1" component={RevenuePerServiceDept1} />
          <PrivateRoute path="/financials/revenue-per-service-department-2" component={RevenuePerServiceDept2} />

          <PrivateRoute path="/visits/new-patients" component={NewPatients} />
          <PrivateRoute path="/visits/patient-visits" component={PatientVisits} />
          <PrivateRoute path="/visits/average-waiting-time" component={AverageWaitingTime} />
          <PrivateRoute path="/visits/doctor-consultation-count" component={DoctorConsultationCount} />

          <PrivateRoute path="/reported-cases/reported-cases-report" component={ReportedCasesReport} />
          <PrivateRoute path="/reported-cases/reported-case-study" component={ReportedCaseStudy} />
          <PrivateRoute path="/reported-cases/top-reported-cases" component={TopReportedCases} />
          <PrivateRoute path="/reported-cases/outbreak-alert" component={OutbreakAlert} />
          <PrivateRoute path="/reported-cases/covid19-alert" component={Covid19Alert} />
          <PrivateRoute path="/reported-cases/covid19-report" component={Covid19Report} />


          <PrivateRoute path="/admissions/admissions-report" component={Admissions} />
          <PrivateRoute path="/admissions/admission-study" component={AdmissionStudy} />
          <PrivateRoute path="/admissions/discharge-report" component={DischargeReport} />
          <PrivateRoute path="/admissions/beds-report" component={BedsReport} />

          <PrivateRoute path="/prescriptions/prescribed-drugs-report" component={PrescribedDrugsReport} />
          <PrivateRoute path="/prescriptions/prescribed-drugs-study" component={PrescribedDrugsStudy} />
          <PrivateRoute path="/prescriptions/top-prescribed-drugs" component={TopPrescribedDrugs} />

          <PrivateRoute path="/lab/lab-tests-report" component={LabTestsReport} />

          <PrivateRoute path="/radiology/radiology-report" component={RadiologyReport} />

          <PrivateRoute path="/births/births-report" component={Births} />
          <PrivateRoute path="/births/labour-room-report" component={LabourRoomReport} />

          <PrivateRoute path="/mortality/inpatient-mortality-report" component={InpatientMortalityReport} />

          <PrivateRoute path="/administration/departments" component={Departments} />
          <PrivateRoute path="/administration/hospitals" component={HospitalList} />

          <PrivateRoute path="/tools/newsletters" component={Newsletters} />
          <PrivateRoute path="/tools/newsletter/configure/:id?" component={NewsletterForm} />
          <PrivateRoute path="/tools/datareset" component={DataReset} />

          <AdminRoute path="/user-management/user-setup" component={UserSetup} />
          <AdminRoute path="/user-management/role-setup" component={RoleSetup} />
          <AdminRoute path="/user-management/role-permission-setup" component={RolePermissionSetup} />
          <AdminRoute path="/user-management/permission-setup" component={PermissionSetup} />

          {userRole === 'Content Writer' ? <Redirect to="/tools/newsletters" /> : userRole === 'FMOF' ? <Redirect to="/financials/home" /> : <Redirect to="/home" />}
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;