import moment from 'moment';
const Constants = {
    // BASE_URL: "https://v3.data.healthinabox.ng/", //live
    BASE_URL: "https://staging.v3.data.healthinabox.ng/", //staging 
    // BASE_URL: "http://127.0.0.1:8000/", //dev
    REFRESH_INTERVAL: 300000,
    SLIDESHOW_INTERVAL: 20000,
    DEFAULT_PERIOD: 'CY',
    DEFAULT_PERIOD_TYPE: '1M',
    DEFAULT_DATA_LIMIT: 10,
    PERIOD_OPTIONS: [
        { label: 'ALL', value: 'ALL' }, 
        { label: 'Current Day', value: 'CD' }, 
        { label: 'Current Week', value: 'CW' }, 
        { label: 'Current Month', value: 'CM' }, 
        { label: 'Current Year', value: 'CY' }, 
        { label: 'Custom', value: 'CU' }
    ],
    PERIOD_TYPE_OPTIONS: [
        { label: 'Daily', value: '1D' }, 
        { label: 'Weekly', value: '1W' }, 
        { label: 'Monthly', value: '1M' }, 
        { label: 'Yearly', value: '1Y' }
    ],
    STATE_OPTIONS : [
        {label: 'All States', value: 'ALL'},
        {label: 'Abia', value: 'Abia'},
        {label: 'Adamawa', value: 'Adamawa'},
        {label: 'Akwa Ibom', value: 'Akwa Ibom'},
        {label: 'Anambra', value: 'Anambra'},
        {label: 'Bauchi', value: 'Bauchi'},
        {label: 'Bayelsa', value: 'Bayelsa'},
        {label: 'Benue', value: 'Benue'},
        {label: 'Borno', value: 'Borno'},
        {label: 'Cross River', value: 'Cross River'},
        {label: 'Delta', value: 'Delta'},
        {label: 'Ebonyi', value: 'Ebonyi'},
        {label: 'Enugu', value: 'Enugu'},
        {label: 'Edo', value: 'Edo'},
        {label: 'Ekiti', value: 'Ekiti'},
        {label: 'Gombe', value: 'Gombe'},
        {label: 'Imo', value: 'Imo'},
        {label: 'Jigawa', value: 'Jigawa'},
        {label: 'Kaduna', value: 'Kaduna'},
        {label: 'Kano', value: 'Kano'},
        {label: 'Katsina', value: 'Katsina'},
        {label: 'Kebbi', value: 'Kebbi'},
        {label: 'Kogi', value: 'Kogi'},
        {label: 'Kwara', value: 'Kwara'},
        {label: 'Lagos', value: 'Lagos'},
        {label: 'Nasarawa', value: 'Nasarawa'},
        {label: 'Niger', value: 'Niger'},
        {label: 'Ogun', value: 'Ogun'},
        {label: 'Ondo', value: 'Ondo'},
        {label: 'Osun', value: 'Osun'},
        {label: 'Oyo', value: 'Oyo'},
        {label: 'Plateau', value: 'Plateau'},
        {label: 'Rivers', value: 'Rivers'},
        {label: 'Sokoto', value: 'Sokoto'},
        {label: 'Taraba', value: 'Taraba'},
        {label: 'Yobe', value: 'Yobe'},
        {label: 'Zamfara', value: 'Zamfara'},
        {label: 'FCT', value: 'FCT'}
    ],
    DATE_RANGE_OPTIONS: {
        'Today': [moment().utc(), moment().utc()],
        'Yesterday': [moment().utc().subtract(1, 'days'), moment().utc().subtract(1, 'days')],
        'Last 7 Days': [moment().utc().subtract(6, 'days'), moment().utc()],
        'Last 30 Days': [moment().utc().subtract(29, 'days'), moment().utc()],
        'This Month': [moment().utc().startOf('month'), moment().utc().endOf('month')],
        'Last Month': [moment().utc().subtract(1, 'month').startOf('month'), moment().utc().subtract(1, 'month').endOf('month')]
    },
    HIERARCHY_LEVEL_OPTIONS: [
        { label: 'National', value: 'National' }, 
        { label: 'Region', value: 'Region' }, 
        { label: 'State', value: 'State' }, 
        { label: 'Hospital', value: 'Hospital' }
    ],

    TABLE_OPTIONS: [
        {label: 'Administrative Totals', value: 'AdministrativeTotals'},
        {label: 'Admission and Discharge', value: 'Admission'},
        {label: 'Beds', value: 'BedCount'},
        {label: 'Births', value: 'Birth'},
        {label: 'Consultations Per Doctor', value: 'DoctorConsultationCount'},
        {label: 'Data Totals', value: 'DataTotals'},
        {label: 'Deaths', value: 'Deaths'},
        {label: 'Departments', value: 'Department'},
        {label: 'Hospital Staff', value: 'HospitalStaff'},
        {label: 'Lab Summary', value: 'LabCounts'},
        {label: 'Lab Tests', value: 'LabTests'},
        {label: 'New Patients', value: 'RegisteredPatients'},
        {label: 'Prescriptions', value: 'PrescribedDrugs'},
        {label: 'Radiology Summary', value: 'RadiologyCount'},
        {label: 'Reported Cases', value: 'Administration'},
        {label: 'Revenue and Transactions', value: 'DailyFinancials'},
        {label: 'Revenue by NHIS and Patient', value: 'RevenueBySchemeAndPatient'},
        {label: 'Revenue Line Items', value: 'RevenueLineItems'},
        {label: 'Revenue per Revenue Department', value: 'RevenueByDepartment'},
        {label: 'Revenue per Service Department', value: 'RevenuePerServiceDepartment'},
        {label: 'Settlement Report', value: 'SettlementReport'},
        {label: 'Visits', value: 'PatientVisits'},
    ],
};

export default Constants